import { useUser } from "@/stores/Auth"
import { usePreviewStore } from "@/stores/Preview"
import { useThemeStore } from "@/stores/Theme"
import clsx from "clsx"
import { computed } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect, useMemo, useRef, useState } from "react"


const sizes = {
    'Computer': 'w-full h-full',
    'Tablet': 'w-[834px] aspect-[7/10] scale-75 absolute',
    'Mobile': 'w-[390px] aspect-[9/18]',
}

export const OFrame = observer(() => {
    const [loaded, setLoaded] = useState(false)
    const ref = useRef<HTMLIFrameElement>(null)
    const user = useUser()
    const themeStore = useThemeStore()
    const preview = usePreviewStore()

    const css = useMemo(() => computed(() => themeStore.css), []).get()

    const url = user?.selectedSite.url
    if (!url) return <></>

    useEffect(() => {
        if (!loaded || css == "") return
        console.log("Posting css")
        ref.current?.contentWindow?.postMessage({
            event: "sb-css-update",
            css: css,
            device: preview.device
        }, url)
    },
    [loaded, css, preview.device])

    return (
        <iframe
            onLoad={() => {
                console.log("Iframe loaded")
                setLoaded(true)
            }}
            className={clsx("bg-white min-h-0 min-w-0", sizes[preview.device])}
            ref={ref}
            src={url}
        />
    )
})